/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2020-07-13 18:13:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  validatePrice = (rule, value, callback) => {
    const re =  /^(0|\+?[1-9][0-9]*)$/;
    if (!value) {
      callback([new Error('请输入名次')]);
    } else {
        if (!re.test(Number(value))) {
            callback([new Error('请输入名次（正整数）')]);
        } else if (Number(value) === 0) {
          callback([new Error('名次不能为0')]);
        } else {
            callback();
        }
   }
  }
  constructor(type) {
    if (type === 'form') {
      this.rank = ''
      this.score = ''
      this.name = ''
      this.cardNo = ''
      // 无需传给后端
      this.filesPicturesPath = []
    } else if (type === 'rule') {
      this.rank = [
        {
          required: true,
          validator: this.validatePrice,
          trigger: 'change',
        },
      ]
      this.score  = [
        {
          required: true,
          message: '请输入成绩',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
