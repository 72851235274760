<template>
  <div class="dialog-container weekBox">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="700px">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
         <el-row>
          <el-col :span="20">
            <el-form-item label="姓名" prop="name">
              <el-input clearable disabled v-model="form.name" :maxlength="20"></el-input>
            </el-form-item>
          </el-col>
         </el-row>
         <el-row>
          <el-col :span="20">
            <el-form-item label="证件号码" prop="cardNo">
              <el-input disabled clearable v-model="form.cardNo"  >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="成绩" prop="score">
              <el-input  v-model="form.score"  clearable>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="名次" prop="rank">
              <el-input type="number" v-model="form.rank" clearable>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <!-- <el-button class="footer-confirm" @click="onPreview">预览</el-button> -->
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
     <!-- 图片预览 -->
    <preview-image ref="previewImage" :show="previewImage.visible" @closeDialog="() => {this.previewImage.visible=false}"></preview-image>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'
import previewImage from "../component/preview.vue";

export default {
  components: {
    previewImage,
  },
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  /* eslint-disable */
  created() {
    console.log("created -> DROPDOWNBOX", this.DROPDOWNBOX.VENUE_APPLY_INFO)
  },
  data() {
    return {
      flag: false,
      tableIndex: null,
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      typeList: [],
      apis,
      showPic: true,
      coachList: [], //教练集合
      applyInfoList: [], //报名列表集合
      editorOption: {
        placeholder: '请输入...',
        modules: {
          toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],

                    [{'header': 1}, {'header': 2}],               // custom button values
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                    [{'direction': 'rtl'}],                         // text direction
                    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                    [{'font': []}],
                    [{'align': []}],
                    ['image', 'formula'] //去除video即可
                ]
        }
      },
      previewImage: {
        visible: false,
        src: "",
      },
      // 结束时间不能大于开始时间
      startTimeOptions: {
        disabledDate: (time) => {
         if (this.form.signUpEndTime) {
            return time.getTime() > new Date(this.form.signUpEndTime).getTime() || time.getTime() < Date.now() - 8.64e7;
          } else {//还没有选择结束时间的时候，让他只能选择今天之后的时间包括今天
            return time.getTime() < Date.now() - 8.64e7
          } 
        },
      },
      endTimeOptions: {
        disabledDate: (time) => {
          if (this.form.signUpStartTime) {
            return time.getTime() <= new Date(this.form.signUpStartTime).getTime() - 8.64e7;
          } else {//还没有选择开始时间的时候，让他只能选择今天之后的时间包括今天
            return time.getTime() < Date.now() - 8.64e7
          }
        },
      },
      eventStartTimeOptions: {
        disabledDate: (time) => {
            return time.getTime() < new Date(this.form.signUpEndTime).getTime();
        },
      },
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', "TOKEN"]),
  },
  methods: {
    validatorPriceList(rule, value, callback) {
      const priceList = this.form.groups.map((item) => {
        return item.price
      })
      const pictrueList = this.form.groups.map((item) => {
        return item.picturePathUrl
      })
      console.log("tablePriceInput -> this.form.price", priceList, this.form.groups.length)
        if (this.form.groups.length > priceList.length) {
            callback([new Error('请填写列表价格')]);
        } else {
            callback();
        }
    },
    tablePriceInput(value, index) {
      let name = ""
      if (!value) {
        name = "请输入价格"
      } else {
        if (Number(value) === 0) {
          name = '价格不能为0'
        } else if (Number(value) < 0) {
          name = "价格不能为负数"
        }
      }
      if (name) {
        this.$message.error(name)
        return false
      }
      let priceList = this.form.groups.map(item => {
        return Number(item.price)
      })
      this.form.price = Math.min(...priceList)
      this.$refs.form.validateField("groups");
      console.log("tablePriceInput -> this.form.price", priceList, this.form.price)
    },
    onTagClose(index) {
      console.log(index)
      this.form.groups.splice(index, 1)
    },
    onGroupAdd(value) {
      if (this.form.groups.filter((item) => item.name === this.form.zubie).length !== 0) {
        this.$message.error("添加组别类型重复！")
        return false
      }
      let index = this.form.groups.length;
      this.form.groups.push({
        index: index,
        name: this.form.zubie,
        price: "",
        picturePath: "",
        picturePathUrl: "",
      })
    },
    validateEndTime(rule, value, callback) {
      const endTime = new Date(this.form.signUpEndTime).getTime()
      const beginTime = new Date(this.form.signUpStartTime).getTime()
      if (!value) {
        callback([new Error('请输入报名截止时间')]);
      } else {
        if (beginTime >= endTime) {
            callback([new Error('报名截止时间需大于报名开始时间')]);
        } else {
            callback();
        }
      }
    },
    onEditChange() {
      console.log("onEditChange")
      this.$refs.form.validateField("detail");
    },
    onApply(value) {
    console.log("onApply -> value", value)
    },
    onPickerChange(value) {
      this.form.startTime = value[0]
      this.form.deadLine = value[1]
    },
    /**
     * @function 文本编辑器
    */
    onEditorReady(editor) {
      console.log(editor)
    },
    onPreview() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.previewImage.visible = true
          this.$nextTick(() => {
            this.$refs['previewImage'].getPreviewData(this.form)
          })
        } else {
          this.changeTap();
        }
      })
    },
    /**
     * 获取教练集合
     */
    getCoachInfo() {
      this.$http.post(apis.coachInfoList, {
        "pageNum": 1,
        "pageSize": 100000,
      }).then((res) => {
        if (res.data.code === 0) {
          this.coachList = res.data.rows
        }
      })
    },
    removeImage(index) {
      this.form.filesPicturesPath.splice(index, 1)
      this.form.files.splice(index, 1)
    },
    uploadSuccess(res) {
      console.log(res)
      this.form.pictureUrl = res.data.url
      this.form.picturePath = res.data.path
      this.$refs.form.validateField("picturePath");
    },
    onTableUpload(index) {
      console.log("onTableUpload -> index", index)
      this.tableIndex = index;
    },
    uploadSuccessTable(res) {
      console.log("uploadSuccessTable", res, this.form.groups, this.tableIndex)
      this.form.groups[this.tableIndex].picturePath = res.data.path
      this.form.groups[this.tableIndex].picturePathUrl = res.data.url
      // this.$refs.form.validateField("picturePath");
    },
    uploadErrorTable(res, index) {
      console.log(res)
    },
    uploadError(res) {
      console.log(res)
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.form = new AddDialogClass('form')
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.flag) { return }
          this.flag = true
          if (this.isEdit) {
            this.$http.put(apis.matchResultEdit, {
              ...this.form,
            }).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.flag = false
                this.closeDialog()
              } else {
                this.flag = false
              }
            })
          } else {
            this.$http.post(apis.matchInfo, {
              ...this.form,
            }).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.flag = false
                this.closeDialog()
              } else {
                this.flag = false
              }
            })
          }
        } else {
          this.changeTap();
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    // 切换tab类型 保存时，如果没有填的要切换tab
    changeTap() {
      if (!this.form.registerCodes || !this.form.detail) {
          this.form.status = "second";
        } else {
          this.form.status = "first";
        }
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.matchResultEdit}/${data.id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = { ...this.form, ...res.data.data }
          console.log("getDataQuery -> this.form ", this.form )
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.el-tag {
    margin-right: 10px;
  }
.tupian-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .tupian-content {
    margin-left: 10px;
  }
  .tupian-image-upload {
    color: #a8a8a8;
  }
}
.group-btn {
  line-height: 40px;
  padding-left: 10px;
}
.price-text {
  padding-left: 10px;
}
.course-text {
  margin-bottom: 20px;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.edit_container {
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
.weekBox {
  .el-checkbox-button {
    margin-right: 5px;
  }
  .ql-editor {
    height: 188px !important;
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
  .course-checkbox {
    .el-checkbox {
      width: 209px !important;
    }
  }
}
</style>
